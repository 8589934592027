import { onDomReady } from "../../utils/on-dom-ready";

type MediumPair = {
  wrapper: HTMLElement;
  medium: HTMLElement;
};

const resizeMediaToSmallestHeight = (textImageTile: HTMLElement) => {
  const wrappers = textImageTile.querySelectorAll<HTMLElement>(
    ".text-image-tile__card-media-wrapper"
  );
  if (!wrappers || wrappers.length <= 1) {
    return;
  }

  if (window.getComputedStyle(textImageTile, null).display !== "grid") {
    for (const wrapper of wrappers) {
      wrapper.style.height = "auto";
    }
    return;
  }

  const mediaMap: MediumPair[] = [];

  for (const wrapper of wrappers) {
    const medium = wrapper.querySelector<HTMLElement>(
      ".text-image-tile__card-media"
    );

    if (!medium) {
      continue;
    }

    mediaMap.push({ wrapper, medium });
  }

  const smallestMediumHeight = Math.min(
    ...mediaMap.map((pair) => pair.medium.clientHeight)
  );

  for (const wrapper of wrappers) {
    wrapper.style.height = `${smallestMediumHeight}px`;
  }
};

onDomReady(() => {
  const textImageTiles =
    document.querySelectorAll<HTMLElement>(".text-image-tile");

  if (!textImageTiles) {
    return;
  }

  for (const textImageTile of textImageTiles) {
    resizeMediaToSmallestHeight(textImageTile);

    window.addEventListener("resize", () => {
      resizeMediaToSmallestHeight(textImageTile);
    });
  }
});
